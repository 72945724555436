import { environment } from "../../environments/environment";

import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const apiUrl = `${environment.backend}/v1/appointments`;

@Injectable({
    providedIn: "root"
})
export class CallService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    get httpOptions() {
      return new Promise(async resolve => {
          const userToken = await this.authService.getUserToken();
          resolve({
              headers: new HttpHeaders({
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${userToken}`
              })
          });
      });
  }

    async requestTurnToken() {
        return this.http
            .post(
                `${apiUrl}/turnTokenRequest`,
                await this.httpOptions
            )
            .toPromise();
    }
}
