import { Component, OnInit, Inject } from "@angular/core";
import { Appointment } from "src/app/models/appointment";
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheetRef
} from "@angular/material/bottom-sheet";
import * as Moment from "moment";
import { AppointmentsService } from "src/app/services/appointments.service";
import { Router } from "@angular/router";
import { DialogComponent } from "../dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-appointment-sheet",
    templateUrl: "./appointment-sheet.component.html",
    styleUrls: ["./appointment-sheet.component.scss"]
})
export class AppointmentSheetComponent implements OnInit {
    loading: boolean = false;

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: Appointment,
        private _bottomSheetRef: MatBottomSheetRef<AppointmentSheetComponent>,
        private appointmentService: AppointmentsService,
        private router: Router,
        private dialog: MatDialog
    ) {}

    get timeString() {
        return Moment(this.data.time).format("HH:mm - DD/MM/YYYY");
    }

    get contact() {
        let string = "";
        if (this.data.patient.email) {
            string += this.data.patient.email;
        }

        if (this.data.patient.email && this.data.patient.mobile) {
            string += " | ";
        }

        if (this.data.patient.mobile) {
            string += this.data.patient.mobile;
        }

        return string;
    }

    ngOnInit() {}

    remindPatient() {
        this.loading = true;
        this.appointmentService
            .remindPatient(this.data)
            .then(() => this._bottomSheetRef.dismiss());
    }

    resendDetails() {
        this.loading = true;
        this.appointmentService
            .resendDetails(this.data)
            .then(() => this._bottomSheetRef.dismiss());
    }

    cancelAppointment() {
        this.loading = true;
        this.appointmentService
            .deleteAppointment(this.data)
            .then(() => this._bottomSheetRef.dismiss());
    }

    joinCall() {
        this.loading = true;
        this._bottomSheetRef.dismiss();
        this.router.navigateByUrl(`/call/${this.data.room_id}`);
    }

    editAppointment() {
        this.loading = true;
        this._bottomSheetRef.dismiss();
        this.router.navigateByUrl(`/appointments/${this.data._id}`);
    }

    getLink() {
        this.loading = true;
        const roomIdString = `${this.data.room_id.substring(
            0,
            3
        )}-${this.data.room_id.substring(3, 6)}-${this.data.room_id.substring(
            6,
            9
        )}`;

        const appointmentUrl = "https://connect.hepian-link.com/";

        const dialogRef = this.dialog.open(DialogComponent, {
            data: {
                heading: "Connection Code",
                message: `The connection code for this meeting is:<br><br><b>${roomIdString}</b><br><br>Tell your patient to enter this code to start their appointment at <a href="${appointmentUrl}">${appointmentUrl}</a>`
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            this._bottomSheetRef.dismiss();
        });
    }
}
