import { Component, OnInit } from "@angular/core";
import { AppointmentsService } from "src/app/services/appointments.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-completed",
    templateUrl: "./completed.component.html",
    styleUrls: ["./completed.component.scss"]
})
export class CompletedComponent implements OnInit {
    roomHash: string;
    loading: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appointmentsService: AppointmentsService
    ) {
        this.roomHash = this.route.snapshot.params.connectionCode;
    }

    ngOnInit() {}

    leaveAppointment() {
        if (this.loading) return;
        this.router.navigateByUrl("/appointments");
    }

    async endAppointment() {
        if (this.loading) return;
        try {
            await this.appointmentsService.deleteAppointment(
                await this.appointmentsService.getAppointmentByRoomId(
                    this.roomHash
                )
            );
        } catch (e) {}
        this.router.navigateByUrl("/appointments");
    }
}
