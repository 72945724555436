import { Component } from "@angular/core";
import { NotificationService } from "./services/notification.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent {
    constructor(
        private notificationService: NotificationService,
        private snackBar: MatSnackBar
    ) {
        this.notificationService.notification.subscribe(message => {
            this.snackBar.open(message, null, {
                duration: 2000,
                panelClass: ["notification"],
                verticalPosition: "top",
                horizontalPosition: "center"
            });
        });
    }

    title = "Hepian Link";
}
