import * as Moment from "moment";
import { Component, OnInit } from "@angular/core";
import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";
import { AppointmentsService } from "src/app/services/appointments.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Appointment } from "src/app/models/appointment";

import { PhoneNumberUtil, PhoneNumber } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: "app-edit-appointment",
    templateUrl: "./edit-appointment.component.html",
    styleUrls: ["./edit-appointment.component.scss"]
})
export class EditAppointmentComponent implements OnInit {
    minimumDate: Date = new Date();
    patientNameText: string = "";
    patientEmailText: string = "";
    patientMobileText: string = "";
    dateText: Date;
    timeText: string = "";
    appointmentId: string;

    serverErrorMessage: string;

    loading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appointmentsService: AppointmentsService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.appointmentId = this.route.snapshot.params.id;
        this.appointmentsService
            .getAppointment(this.appointmentId)
            .then((appointment: Appointment) => {
                this.patientNameText = appointment.patient.name;
                this.patientEmailText = appointment.patient.email;
                this.patientMobileText = appointment.patient.mobile;
                this.dateText = Moment(appointment.time).toDate();
                this.timeText = Moment(appointment.time).format("HH:mm");
                this.loading = false;
            });
    }

    public get minimumTime(): string {
        return Moment(this.dateText).isSame(Moment(), "day")
            ? Moment().format("HH:mm")
            : null;
    }

    public get dateTime(): Moment.Moment {
        const timeMoment = Moment(this.timeText, "HH:mm");
        return Moment(this.dateText)
            .hours(timeMoment.hours())
            .minutes(timeMoment.minutes());
    }

    timepickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: "#02aab0",
            primaryFontFamily: "Rubik"
        },
        dial: {
            dialBackgroundColor: "#02aab0"
        },
        clockFace: {
            clockHandColor: "#02aab0"
        }
    };

    async onSubmit(appointmentForm) {
        if (this.loading) return;
        this.loading = true;
        this.serverErrorMessage = null;
        if (!appointmentForm.valid || !this.validatePhone()) {
            this.loading = false;
            return;
        }

        if (this.dateTime.isBefore(Moment())) {
            this.serverErrorMessage =
                "Please select a date and time in the future.";
            this.loading = false;
            return;
        }

        await this.appointmentsService
            .updateAppointment(this.appointmentId, {
                patient: {
                    name: this.patientNameText,
                    email: this.patientEmailText || undefined,
                    mobile: this.patientMobileText
                        ? this.patientMobileText.replace(/\s/g, "")
                        : undefined
                },
                time: this.dateTime.toISOString()
            })
            .catch(error => {
                console.error(error);
                this.serverErrorMessage =
                    "There was an error updating your appointment.";
                this.loading = false;
            });

        this.loading = false;
        this.router.navigateByUrl("/appointments");
    }

    validatePhone() {
        if (!this.patientMobileText) return false;
        try {
            let number = phoneUtil.parse(this.patientMobileText);
            return phoneUtil.isValidNumber(number);
        } catch (e) {
            return false;
        }
    }
}
