import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import {
    AngularFireAuthGuard,
    redirectUnauthorizedTo,
    redirectLoggedInTo
} from "@angular/fire/auth-guard";

import { CallComponent } from "./components/call/call.component";
import { CompletedComponent } from "./components/completed/completed.component";
import { LoginComponent } from "./components/login/login.component";
import { AppointmentsComponent } from "./components/appointments/appointments.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { LogoutGuard } from "./services/logout-guard.service";
import { RegisterComponent } from "./components/register/register.component";
import { CreateAppointmentComponent } from "./components/create-appointment/create-appointment.component";
import { EditAppointmentComponent } from "./components/edit-appointment/edit-appointment.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLoggedInToAppointments = () =>
    redirectLoggedInTo(["appointments"]);

const routes: Routes = [
    { path: "", redirectTo: "/login", pathMatch: "full" },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToAppointments }
    },
    {
        path: "register",
        component: RegisterComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToAppointments }
    },
    {
        path: "appointments",
        component: AppointmentsComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    },
    {
        path: "appointments/create",
        component: CreateAppointmentComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    },
    {
        path: "appointments/:id",
        component: EditAppointmentComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    },
    {
        path: "logout",
        component: LogoutComponent,
        canActivate: [AngularFireAuthGuard, LogoutGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    },
    {
        path: "completed/:connectionCode",
        component: CompletedComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    },
    {
        path: "call/:connectionCode",
        component: CallComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
