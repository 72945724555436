import * as Moment from "moment";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatCardModule, MatInputModule } from "@angular/material";
import { HttpClient } from "@angular/common/http";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { AppointmentsService } from "../../services/appointments.service";
import { DialogComponent } from "../dialog/dialog.component";
import { Router } from "@angular/router";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
    connectionCode = "";

    constructor(
        private router: Router,
        private appointmentsService: AppointmentsService,
        private errorDialog: MatDialog
    ) {}

    ngOnInit() {}

    async onSubmit(connectForm) {
        if (!connectForm.valid) return;
        const strippedConnectionCode = this.connectionCode.replace(/\W/g, "");
        console.log("Submitted: " + strippedConnectionCode);
        try {
            const appt = await this.appointmentsService.getAppointmentByRoomId(
                strippedConnectionCode
            );
            if (
                Moment().isBefore(
                    Moment(appt.time).subtract(
                        Moment.duration({ days: 10, minutes: 60 })
                    )
                )
            ) {
                const dialogRef = this.errorDialog.open(DialogComponent, {
                    width: "250px",
                    data: {
                        heading: "Too early!",
                        message:
                            "It looks like you've arrived early! Please come back up to an hour before your appointment."
                    }
                });
                return;
            }

            this.router.navigateByUrl(`/${strippedConnectionCode}`);
        } catch (error) {
            const dialogRef = this.errorDialog.open(DialogComponent, {
                width: "250px",
                data: {
                    heading: "Oops!",
                    message: "It looks like that appointment doesn't exist!"
                }
            });
            return;
        }
    }
}
