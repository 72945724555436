import { Injectable } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private user: Observable<firebase.User>;
    private userDetails: firebase.User = null;

    constructor(private router: Router, private afAuth: AngularFireAuth) {
        this.user = this.afAuth.user;
        this.user.subscribe(user => {
            if (user) {
                this.userDetails = user;
            } else {
                this.userDetails = null;
            }
        });
    }

    doGoogleLogin() {
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        return this.afAuth.auth.signInWithPopup(provider);
    }

    doFacebookLogin() {
        let provider = new firebase.auth.FacebookAuthProvider();
        provider.setCustomParameters({
            display: "popup"
        });
        return this.afAuth.auth.signInWithPopup(provider);
    }

    doEmailPasswordLogin(email: string, password: string) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }

    doRegisterEmailPassword(email: string, password: string) {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    }

    doSetAccountName(name: string) {
        return this.afAuth.auth.currentUser.updateProfile({
            displayName: name
        });
    }

    doLogout() {
        return this.afAuth.auth
            .signOut()
            .then(res => this.router.navigateByUrl("/"));
    }

    isLoggedIn() {
        return this.userDetails !== null;
    }

    getUserToken() {
        return this.afAuth.auth.currentUser.getIdToken();
    }
}
