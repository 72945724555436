import * as Moment from "moment";
import { Component, OnInit } from "@angular/core";
import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";
import { AppointmentsService } from "src/app/services/appointments.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../dialog/dialog.component";
import { Appointment } from "src/app/models/appointment";

import { ConfigurationOptions } from "intl-input-phone";

import { PhoneNumberUtil, PhoneNumber } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: "app-create-appointment",
    templateUrl: "./create-appointment.component.html",
    styleUrls: ["./create-appointment.component.scss"],
})
export class CreateAppointmentComponent implements OnInit {
    minimumDate: Date = new Date();
    patientNameText: string = "";
    patientEmailText: string = "";
    patientMobileText: string = "";
    dateText: Date;
    timeText: string = "";

    serverErrorMessage: string;

    loading: boolean = false;

    phoneInputConfig: ConfigurationOptions = new ConfigurationOptions();

    constructor(
        private router: Router,
        private appointmentsService: AppointmentsService,
        private dialog: MatDialog
    ) {}

    public get minimumTime(): string {
        return Moment(this.dateText).isSame(Moment(), "day")
            ? Moment().format("HH:mm")
            : null;
    }

    public get dateTime(): Moment.Moment {
        const timeMoment = Moment(this.timeText, "HH:mm");
        return Moment(this.dateText)
            .hours(timeMoment.hours())
            .minutes(timeMoment.minutes());
    }

    timepickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: "#02aab0",
            primaryFontFamily: "Rubik",
        },
        dial: {
            dialBackgroundColor: "#02aab0",
        },
        clockFace: {
            clockHandColor: "#02aab0",
        },
    };

    ngOnInit() {}

    async onSubmit(appointmentForm) {
        if (this.loading) return;
        console.log(this.patientMobileText);
        this.loading = true;
        this.serverErrorMessage = null;
        if (!appointmentForm.valid || !this.validatePhone()) {
            this.loading = false;
            return;
        }

        if (this.dateTime.isBefore(Moment())) {
            this.serverErrorMessage =
                "Please select a date and time in the future.";
            this.loading = false;
            return;
        }

        await this.appointmentsService
            .createAppointment({
                patient: {
                    name: this.patientNameText,
                    email: this.patientEmailText || undefined,
                    mobile: this.patientMobileText
                        ? this.patientMobileText.replace(/\s/g, "")
                        : undefined,
                },
                time: this.dateTime.toISOString(),
            })
            .then((res: any) => {
                if (!this.patientEmailText && !this.patientMobileText) {
                    const appointment: Appointment = res;
                    console.log(appointment);
                    const roomIdString = `${appointment.room_id.substring(
                        0,
                        3
                    )}-${appointment.room_id.substring(
                        3,
                        6
                    )}-${appointment.room_id.substring(6, 9)}`;

                    const appointmentUrl = "https://connect.hepian-link.com/";

                    const dialogRef = this.dialog.open(DialogComponent, {
                        data: {
                            heading: "Connection Code",
                            message: `The connection code for this meeting is:<br><br><b>${roomIdString}</b><br><br>Tell your patient to enter this code to start their appointment at <a href="${appointmentUrl}">${appointmentUrl}</a>`,
                        },
                    });

                    dialogRef.afterClosed().subscribe(() => {
                        this.router.navigateByUrl("/appointments");
                    });
                }

                this.loading = false;
                this.router.navigateByUrl("/appointments");
            })
            .catch((error) => {
                console.error(error);
                this.serverErrorMessage =
                    "There was an error adding your appointment.";
                this.loading = false;
            });
    }

    validatePhone() {
        if (!this.patientMobileText) return false;
        try {
            let number = phoneUtil.parse(this.patientMobileText);
            return phoneUtil.isValidNumber(number);
        } catch (e) {
            return false;
        }
    }
}
