import { Directive, forwardRef } from "@angular/core";
import {
    NG_VALIDATORS,
    AbstractControl,
    ValidatorFn,
    Validator,
    FormControl
} from "@angular/forms";

import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

// validation function
function validatePhoneFactory(): ValidatorFn {
    return (c: AbstractControl) => {
        const invalid = {
            phone: {
                valid: false
            }
        };
        if (c.value === null || c.value === undefined || c.value === "")
            return null;
        if (!phoneUtil.isPossibleNumberString(c.value, "UK")) return invalid;
        const number = phoneUtil.parseAndKeepRawInput(c.value);
        return phoneUtil.isValidNumber(number) ? null : invalid;
    };
}

@Directive({
    selector: "[phone][ngModel]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: PhoneValidator, multi: true }
    ]
})
export class PhoneValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = validatePhoneFactory();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }
}
