import * as Moment from "moment";
import { Component, OnInit } from "@angular/core";
import {
    MatBottomSheet,
    MatBottomSheetRef
} from "@angular/material/bottom-sheet";

import { AppointmentsService } from "src/app/services/appointments.service";
import { Appointment } from "src/app/models/appointment";
import { AppointmentSheetComponent } from "../appointment-sheet/appointment-sheet.component";

@Component({
    selector: "app-appointments",
    templateUrl: "./appointments.component.html",
    styleUrls: ["./appointments.component.scss"]
})
export class AppointmentsComponent implements OnInit {
    appointments: Appointment[] = null;
    loadingAppointments: boolean = true;

    get anyAppointments(): boolean {
        return this.appointments && this.appointments.length > 0;
    }

    get splitAppointments() {
        return this.datesGroupByComponent(this.appointments, "YYYY-MM-DD");
    }

    constructor(
        private appointmentsService: AppointmentsService,
        private _bottomSheet: MatBottomSheet
    ) {
        this.appointmentsService.appointmentList.subscribe(
            (appointments: Appointment[]) => {
                this.appointments = appointments;
            }
        );
    }

    get loaded(): boolean {
        return this.appointments !== null;
    }

    ngOnInit() {}

    sortAppointments(appointments: Appointment[]): Appointment[] {
        return appointments.sort(
            (a, b) => Moment(a.time).unix() - Moment(b.time).unix()
        );
    }

    datesGroupByComponent(dates, token) {
        return dates.reduce((val, obj) => {
            let comp = Moment(obj["time"]).format(token);
            (val[comp] = val[comp] || []).push(obj);
            return val;
        }, {});
    }

    getStringForDate(date: string) {
        return Moment(date, "YYYY-MM-DD").calendar(null, {
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            nextWeek: "dddd",
            lastDay: "[Yesterday]",
            lastWeek: "[Last] dddd",
            sameElse: "DD/MM/YYYY"
        });
    }

    getStringForTime(time: string) {
        return Moment(time).format("HH:mm");
    }

    openBottomSheet(appointment: Appointment): void {
        this._bottomSheet.open(AppointmentSheetComponent, {
            data: appointment
        });
    }

    reload() {
        this.appointmentsService.loadAppointments();
    }
}
