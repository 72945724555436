import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { InviteService } from "src/app/services/invite.service";
import { Invite } from "src/app/models/invite";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
    emailText: string = "";
    passwordText: string = "";
    nameText: string = "";
    serverErrorMessage: string = null;
    loadingEmail: boolean = false;
    loadingGoogle: boolean = false;
    loadingFacebook: boolean = false;
    inviteId: string = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private inviteService: InviteService
    ) {
        if (this.route.snapshot.queryParamMap.has("invite")) {
            this.handleInvite(this.route.snapshot.queryParamMap.get("invite"));
        }
    }

    async handleInvite(inviteId: string) {
        this.loadingEmail = true;

        try {
            const invite = await this.inviteService.getInviteById(inviteId);

            if (invite.accepted) {
                throw {
                    message: "Invite already accepted. Invite will be ignored.",
                };
            }
            
            this.inviteId = invite._id;
            this.emailText = invite.invitee.email;
            this.nameText = invite.invitee.name;
        } catch (err) {
            console.warn(err.message);
        }

        this.loadingEmail = false;
    }

    ngOnInit() {}

    async onSubmit(registerForm) {
        if (this.loadingEmail) return;
        this.loadingEmail = true;
        this.serverErrorMessage = null;

        if (!registerForm.valid) {
            this.loadingEmail = false;
            return;
        }

        try {
            await this.authService.doRegisterEmailPassword(
                this.emailText,
                this.passwordText
            );

            await this.authService.doSetAccountName(this.nameText);

            if (this.inviteId)
                await this.inviteService.acceptInviteById(this.inviteId);

            this.loadingEmail = false;
            this.redirect();
        } catch (err) {
            this.serverErrorMessage = err.message;
            this.loadingEmail = false;
        }
    }

    facebookLogin() {
        if (this.loadingEmail || this.loadingFacebook || this.loadingGoogle)
            return;
        this.loadingFacebook = true;
        this.authService
            .doFacebookLogin()
            .then(() => {
                this.loadingFacebook = false;
                this.redirect();
            })
            .catch(() => {
                this.loadingFacebook = false;
            });
    }

    googleLogin() {
        if (this.loadingEmail || this.loadingFacebook || this.loadingGoogle)
            return;
        this.loadingGoogle = true;
        this.authService
            .doGoogleLogin()
            .then(() => {
                this.loadingGoogle = false;
                this.redirect();
            })
            .catch(() => {
                this.loadingGoogle = false;
            });
    }

    redirect() {
        console.log("redirect");
        this.router.navigateByUrl("/appointments");
    }
}
