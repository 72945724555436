import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { codeList } from "./phone-codes";

import {
    PhoneNumberUtil,
    PhoneNumber,
    PhoneNumberFormat,
    AsYouTypeFormatter,
} from "google-libphonenumber";
import { ControlValueAccessor } from "@angular/forms";

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: "app-phone",
    templateUrl: "./phone.component.html",
    styleUrls: ["./phone.component.scss"],
})
export class PhoneComponent implements OnInit, ControlValueAccessor {
    @Input() placeholder: string = "";
    @Input() disabled: boolean = false;
    @Input() set value(value: string) {
        if (value) {
            try {
                this._number = phoneUtil.parseAndKeepRawInput(value);
            } catch (e) {}
        } else {
            this._number = new PhoneNumber();
            this._number.setCountryCode(44);
        }
    }
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

    _number: PhoneNumber = new PhoneNumber();
    _formatter: AsYouTypeFormatter = new AsYouTypeFormatter("GB");

    onChange: Function = () => {};
    onTouched: Function = () => {};

    codeList = codeList.sort(
        (a, b) =>
            a[1].toString().length - b[1].toString().length ||
            parseInt(a[1].toString()) - parseInt(b[1].toString())
    );

    getFullNumber() {
        return `+${this.areaCode}${this.localNumber}`;
    }

    get localNumber() {
        let nationalNumber = this._number.getNationalNumber();
        if (!nationalNumber || isNaN(nationalNumber)) {
            return "";
        } else {
            return nationalNumber.toString();
        }
    }

    set localNumber(value: string) {
        try {
            if (!value) {
                this._number.clearNationalNumber();
            } else {
                this._number.setNationalNumber(parseInt(value));
            }
        } catch (e) {}
        this.emitUpdate();
    }

    get areaCode() {
        return this._number.getCountryCode().toString();
    }

    set areaCode(value: string) {
        this._number.setCountryCode(parseInt(value));
        this.emitUpdate();
    }

    constructor() {}

    writeValue(value): void {
        this.value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnInit() {}

    getFlagUrl(countryName: string) {
        return `/assets/flags/${countryName
            .replace(/\s/g, "-")
            .replace(/\./g, "")
            .toLowerCase()}.svg`;
    }

    emitUpdate() {
        this.valueChange.emit(
            phoneUtil.format(this._number, PhoneNumberFormat.E164)
        );
    }
}
