import { environment } from "../../environments/environment";

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Appointment, CreateAppointment } from "../models/appointment";
import { AuthService } from "./auth.service";
import * as Moment from "moment";
import { Observable, BehaviorSubject } from "rxjs";
import { NotificationService } from "./notification.service";

declare var ScaleDrone: any;

const apiUrl = `${environment.backend}/v1/appointments`;

@Injectable({
    providedIn: "root"
})
export class AppointmentsService {
    private _appointmentList: BehaviorSubject<
        Appointment[]
    > = new BehaviorSubject(null);
    public readonly appointmentList: Observable<
        Appointment[]
    > = this._appointmentList.asObservable();
    lastLoaded: Moment.Moment;
    cacheExpiryTime: Moment.Duration = Moment.duration(1, "minutes");
    rooms: Map<string, any> = new Map<string, any>();
    drone = new ScaleDrone("37nBkemedi06JCT1", {
        data: {
            type: "observer"
        }
    });

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private notificationService: NotificationService
    ) {
        this.loadAppointments().then(() => {
            this.drone.on("open", error => {
                if (error) return console.log(error);

                this.updateDroneSubscriptions();
            });
        });

        this.appointmentList.subscribe(val => console.log(val));
    }

    cacheHasExpired(): boolean {
        return (
            !this._appointmentList.getValue() ||
            Moment(this.lastLoaded)
                .add(this.cacheExpiryTime)
                .isBefore(Moment())
        );
    }

    updateDroneSubscriptions() {
        this._appointmentList
            .getValue()
            .forEach(appointment =>
                this.subscribeDroneToRoom(appointment.room_id)
            );
    }

    subscribeDroneToRoom(room_id) {
        const room = this.drone.subscribe(`observable-${room_id}`);

        room.on("members", (members: Array<any>) => {
            this.setWaitingForAppointment(
                room_id,
                members.filter(member => member.clientData.type === "patient")
                    .length
            );
        });
        room.on("member_join", (member: any) => {
            console.log("member_join");
            console.log(member);
            if (member.clientData.type === "patient") {
                this.setWaitingForAppointment(room_id, 1);
            }
        });
        room.on("member_leave", (member: any) => {
            console.log("member_leave");
            console.log(member);
            if (member.clientData.type === "patient") {
                this.setWaitingForAppointment(room_id, -1);
            }
        });
    }

    setWaitingForAppointment(room_id: string, waitingCountDiff: number) {
        const tempAppointmentList = this._appointmentList.getValue();
        tempAppointmentList.find(appointment => {
            if (appointment.room_id === room_id) {
                const oldCount = appointment.patientWaitingCount || 0;
                appointment.patientWaitingCount = oldCount + waitingCountDiff;
                if (oldCount === 0 && appointment.patientWaitingCount > 0) {
                    this.notificationService.notification.next(
                        `${appointment.patient.name} is waiting for their appointment`
                    );
                }
                console.log(appointment.patientWaitingCount);
                return true;
            }
            return false;
        });
        this._appointmentList.next(Object.assign([], tempAppointmentList));
    }

    async loadAppointments() {
        const appointments = await this.http
            .get<Appointment[]>(`${apiUrl}`, await this.httpOptions)
            .toPromise()
            .catch(err => {
                return new Array<Appointment>();
            });
        const currentAppointmentList = this._appointmentList.getValue();
        if (currentAppointmentList) {
            appointments.forEach(
                elem =>
                    (elem.patientWaitingCount = this._appointmentList
                        .getValue()
                        .find(val => val._id == elem._id).patientWaitingCount)
            );
        }
        this._appointmentList.next(appointments);
        this.lastLoaded = Moment();
        this.updateDroneSubscriptions();
    }

    get httpOptions() {
        return new Promise(async resolve => {
            const userToken = await this.authService.getUserToken();
            resolve({
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${userToken}`
                })
            });
        });
    }

    async getAppointment(id: string): Promise<Appointment> {
        return this.cacheHasExpired()
            ? await this.http.get<Appointment>(`${apiUrl}/${id}`, await this.httpOptions).toPromise()
            : this._appointmentList
                  .getValue()
                  .find(appointment => appointment._id == id);
    }

    async getAppointmentByRoomId(id: string): Promise<Appointment> {
        return this.cacheHasExpired()
            ? await this.http
                  .get<Appointment>(`${apiUrl}/room/${id}`)
                  .toPromise()
            : this._appointmentList
                  .getValue()
                  .find(appointment => appointment.room_id == id);
    }

    async createAppointment(appointment: CreateAppointment) {
        return this.http
            .post(`${apiUrl}`, appointment, await this.httpOptions)
            .toPromise()
            .then((appointment: Appointment) => {
                const next: Appointment[] = this._appointmentList.getValue();
                next.push(appointment);
                this._appointmentList.next(next);
                this.loadAppointments();
                console.log(appointment);
                return appointment;
            });
    }

    async updateAppointment(id: string, appointment: CreateAppointment) {
        return this.http
            .put(`${apiUrl}/${id}`, appointment, await this.httpOptions)
            .toPromise()
            .then((appointment: Appointment) => {
                const next: Appointment[] = this._appointmentList.getValue();
                next[
                    next.findIndex(element => element._id === id)
                ] = appointment;
                this._appointmentList.next(next);
                this.loadAppointments();
                console.log(appointment);
                return appointment;
            });
    }

    async deleteAppointment(appointment: Appointment) {
        return this.http
            .delete(`${apiUrl}/${appointment._id}`, await this.httpOptions)
            .toPromise()
            .then((deleted: Appointment) => this.loadAppointments());
    }

    async remindPatient(appointment: Appointment) {
        return this.http
            .post(
                `${apiUrl}/${appointment._id}/reminderRequest`,
                appointment,
                await this.httpOptions
            )
            .toPromise();
    }

    async resendDetails(appointment: Appointment) {
        return this.http
            .post(
                `${apiUrl}/${appointment._id}/resendRequest`,
                appointment,
                await this.httpOptions
            )
            .toPromise();
    }
}
